
module.exports = (function(){
  var d = _eai_d;
  var r = _eai_r;
  window.emberAutoImportDynamic = function(specifier) {
    if (arguments.length === 1) {
      return r('_eai_dyn_' + specifier);
    } else {
      return r('_eai_dynt_' + specifier)(Array.prototype.slice.call(arguments, 1))
    }
  };
  window.emberAutoImportSync = function(specifier) {
    return r('_eai_sync_' + specifier)(Array.prototype.slice.call(arguments, 1))
  };
  d('__v1-addons__early-boot-set__', ['@glimmer/tracking','@glimmer/component','@ember/service','@ember/controller','@ember/routing/route','@ember/component'], function() {});
    d('@sentry/browser', ['__v1-addons__early-boot-set__'], function() { return require('@sentry/browser'); });
    d('@sentry/utils', ['__v1-addons__early-boot-set__'], function() { return require('@sentry/utils'); });
    d('ember-file-upload/components/file-dropzone', ['__v1-addons__early-boot-set__'], function() { return require('ember-file-upload/components/file-dropzone'); });
    d('ember-file-upload/helpers/file-queue', ['__v1-addons__early-boot-set__'], function() { return require('ember-file-upload/helpers/file-queue'); });
    d('ember-file-upload/services/file-queue', ['__v1-addons__early-boot-set__'], function() { return require('ember-file-upload/services/file-queue'); });
    d('fast-memoize', ['__v1-addons__early-boot-set__'], function() { return require('fast-memoize'); });
    d('intl-messageformat', ['__v1-addons__early-boot-set__'], function() { return require('intl-messageformat'); });
    d('intl-messageformat-parser', ['__v1-addons__early-boot-set__'], function() { return require('intl-messageformat-parser'); });
    d('luxon', ['__v1-addons__early-boot-set__'], function() { return require('luxon'); });
    d('validated-changeset', ['__v1-addons__early-boot-set__'], function() { return require('validated-changeset'); });
    d('_eai_dyn_@sentry/tracing', [], function() { return import('@sentry/tracing'); });
})();
